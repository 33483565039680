<template>
  <a-card>
    <div class="flex items-top">
      <div class="w-full md:w-1/6 lg:w-1/6 px-2 inline-block">
        <a-input
          required
          label="Número de semestre"
          label-class="font-bold text-gray-700"
          :id="`input_semester_${index}`"
          :error="error(`semesters.${index}.number`, { attribute: 'semestre', value: () => 'materia' })"
          type="number"
          :value="value.number"
          @input="$emit('input', {...value, number: parseInt($event) })" />
      </div>
      <div class="w-full md:w-4/6 lg:w-4/6 px-2 inline-block">
        <div class="flex">
          <b class="inline-block text-gray-700 mr-2">Materias</b>
          <span class="inline-block text-red-600 font-bold">*</span>
        </div>
        <a-dropdown
          placement="left"
          width="w-96"
          v-if="(isUpdating && semester.hasOwnProperty('id') || !isUpdating)"
          keep-open-on-click>

          <button class="py-2">
            Agregar materia
          </button>

          <template #menu>
            <a-input placeholder="materia..." is-search v-model="search" />
            <a-dropdown-item
              v-for="(course, course_index) in filteredCourses"
              :key="course.id"
              class="w-full py-2">
              <div class="flex justify-between space-x-2 w-full">
                <label :for="`course_input_${index}_${course_index}`" class="inline-flex space-x-2 items-center cursor-pointer select-none">
                  <input
                    type="checkbox"
                    :id="`course_input_${index}_${course_index}`"
                    :value="course.id"
                    v-model="value.courses_ids"
                    class="appearance-none cursor-pointer border checked:ring-2 checked:ring-primary w-4 h-4 checked:border-transparent ring-offset-2 border rounded border-gray-200 active:scale-95 transition-all transform checked:bg-primary">
                  <span>
                    {{ course.code + ' ' + course.title }}
                  </span>
                </label>
              </div>
            </a-dropdown-item>
          </template>
        </a-dropdown>
        <div class="w-full flex flex-wrap gap-2">
          <a-badge class="bg-gray-100" v-for="(id, key) in value.courses_ids" :key="id" @close="removeCourseId(key)">
            <slot v-bind="{ current: id }" />
          </a-badge>
        </div>
        <p class="text-red-500">
          {{ error(`semesters.${index}.courses_ids`, { attribute: 'materia', value: () => 'semestre' }) }}
        </p>
      </div>
    </div>
    <div class="flex space-x-2">
      <template v-if="isUpdating && semester.hasOwnProperty('id')">
        <a-button @click="update" class="mr-2">
          Actualizar semestre
        </a-button>
      </template>
      <template v-else>
        <a-button v-if="isUpdating" @click="store(semester, index)">
          Crear semestre
        </a-button>
        <a-button scheme="warning" @click="$emit('remove', index)">
          Eliminar semestre
        </a-button>
      </template>
    </div>
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "semester-row",
  props: {
    isUpdating: { required: true, type: Boolean },
    index: { required: true, type: Number },
    semester: { required: true, type: Object },
    value: { required: true, type: Object },
    courses: { required: true, type: Array },
  },
  data: () => ({
    loadingCourses: false,
    search: ''
  }),
  computed: {
    ...mapGetters(['error']),
    filteredCourses () {
      return this.courses
        .filter(
          course => course.title
            .toLowerCase()
            .includes(this.search.toLowerCase())
        )
    }
  },
  methods: {
    removeCourseId (key) {
      this.$emit('input', {
        ...this.value,
        courses_ids: this.value.courses_ids.filter((_, current) => {
          return key !== current
        })
      })
    },
    update () {
      this.$emit('update', this.semester)
    },
    store () {
      this.$emit('store', this.semester)
    }
  }
}
</script>